import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const typing = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const TypingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 70%;
  min-width: 120px;
  padding: 12px;
  background-color: #e9e9eb;
  color: black;
  border-radius: 8px;
  margin-bottom: 16px;
  animation: ${fadeInFromBottom} 0.3s ease-out;
`;

const TypingDots = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: black;
  border-radius: 50%;
  animation: ${typing} 1s infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const TypingIndicator: FC = () => {
  return (
    <TypingIndicatorContainer>
      <TypingDots>
        <Dot />
        <Dot />
        <Dot />
      </TypingDots>
    </TypingIndicatorContainer>
  );
};

export default TypingIndicator;
