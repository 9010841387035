import axios from 'axios';
import { IChatConversation, IChatMessage, WidgetConfig } from "../interface";

// const API_URL = 'https://api-v2.heyai.pro'
// // const API_URL = 'http://127.0.0.1:4000'  // Use localhost for development
export const API_URL = process.env.NODE_ENV === 'development'
  ? 'http://127.0.0.1:4000'  // Use localhost for development
  : 'https://api-v2.heyai.pro';  // Use production URL for other environments

// Create an Axios instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to set global headers for the Axios instance
export const setApiKeyAndReferrer = (apiKey: string, referrer: string) => {
  api.defaults.headers['X-API-Key'] = apiKey;
  api.defaults.headers['X-Widget-Referer'] = referrer;
};

export async function ping() {
  const response = await api.get(`/v2/concierge/ping`);
  if (response.status !== 200) {
    throw new Error('Failed to ping');
  }
  return true;
}

export function gdriveProxy(id: string) {
  return `${API_URL}/v2/proxy/gdrive/${id}`;
}

export async function fetchConfig(): Promise<WidgetConfig> {
  const response = await api.get(`/v2/concierge/config`);
  return response.data as WidgetConfig;
}

/**
 * Sends a message to the backend.
 * @param text - The message to be sent.
 * @returns A promise that resolves to the backend's response.
 */
export async function sendMessage(userId: string, message: IChatMessage): Promise<IChatMessage | null> {
  const queryParams = {
    userId,
    platform: 'widget',
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const response = await api.post(`/v2/concierge/chat?${queryString}`, {
    text: message.body,
    attachments: message.attachmentContents?.map((attachment) => ({
      ...attachment,
      payload: {
        ...attachment.payload,
        content: attachment.payload.content.replace(/^data:image\/(jpeg|png|jpg|gif);base64,/, ''),
      },
    })),
  });

  if (response.status !== 200) {
    throw new Error('Failed to send message');
  }
  if (response.data === null) {
    return null
  }

  return response.data as IChatMessage;
}

export async function cancelMessage(): Promise<boolean> {
  const response = await api.post(`/v2/concierge/chat/cancel`);
  return response.status === 200;
}

export async function getConversation(userId: string): Promise<IChatConversation> {
  const queryParams = {
    userId,
    platform: 'widget',
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const response = await api.get(`/v2/concierge/chat/history?${queryString}`);
  return response.data as IChatConversation;
}

export async function deleteHistory(userId: string): Promise<boolean> {
  const queryParams = {
    userId,
    platform: 'widget',
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const response = await api.delete(`/v2/concierge/chat/history?${queryString}`);
  return response.status === 200;
}

export async function loadAttachment(userId: string, attachmentId: string) {
  const queryParams = {
    userId,
    platform: 'widget',
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const response = await api.get(`/v2/concierge/chat/attachments/${attachmentId}?${queryString}`, { responseType: 'blob' });
  if (response.status !== 200) {
    throw new Error('No data returned');
  }

  const url = window.URL.createObjectURL(response.data);
  return url;
}
