import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import refreshIcon from '../assets/refresh.svg';
import closeIcon from '../assets/close.svg';
import conciergeLogo from '../assets/concierge.svg';

interface Props {
  refresh: () => Promise<void>;
  close: () => void;
  disabled: boolean;
}

const rem = (px: number): string => `${px / 16}rem`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  color: white;
  padding: ${rem(16)};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const IconButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: 0;
  margin-right: ${rem(8)};

  &:last-child {
    margin-right: 0;
  }
`;

const Tooltip = styled.div<{ disabled: boolean }>`
  display: ${(props) => (props.disabled ? 'none' : 'block')};
  position: absolute;
  bottom: ${rem(-30)};
  right: 0;
  background: #333;
  color: #fff;
  padding: ${rem(5)};
  border-radius: ${rem(3)};
  font-size: ${rem(12)};
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1000;

  ${IconButton}:hover & {
    opacity: 1;
  }
`;

const ChatHeader: FC<Props> = ({ refresh, close, disabled }) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={conciergeLogo} alt="Concierge" style={{ height: rem(32) }} />
      </LogoContainer>
      <ButtonContainer>
        <IconButton
          onClick={refresh}
          disabled={disabled}
        >
          <img src={refreshIcon} alt="Refresh" />
          <Tooltip disabled={disabled}>{t('chatbot.refresh')}</Tooltip>
        </IconButton>
        <IconButton
          onClick={close}
        >
          <img src={closeIcon} alt="Close" />
        </IconButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default ChatHeader;
