export enum ChatRole {
  User = 'user',
  Ai = 'ai',
  Staff = 'staff',
  System = 'system', // this is a system message to our users (e.g. error message)
}

export interface IChatAttachment {
  id: string;
  name?: string;
  size?: number;
  type: string;
  path?: string;
  extracted?: string;
  // preview: string;
  // createdAt: Date;
  // modifiedAt: Date;
}

export interface ChatMessageRequest {
  text: string
  attachments?: Array<ChatAttachmentRequestDto>
  taskAction?: string
}

export interface ChatAttachmentRequestDto {
  type: ChatAttachmentType
  name?: string
  payload: ChatAttachmentRequestPayloadDto
}

export interface ChatAttachmentRequestPayloadDto {
  content: string
  contentType: string
}

export enum ChatOutcomeType {
  Chat = 'enterprise_generic_chat',
  CollectData = 'enterprise_collect_data',
  CorrectData = 'enterprise_correct_data',
  HandOver = 'enterprise_hand_over',
  IdentifiedCustomer = 'enterprise_identified_customer',
  Booking = 'enterprise_booking',
  BranchDiscovery = 'enterprise_branch_discovery',
  QueryInternet = 'enterprise_query_internet',
  QueryDocuments = 'enterprise_query_documents',
  QueryProducts = 'enterprise_query_products',
  QueryBooking = 'enterprise_query_booking',
}

export interface IProductMetadata {
  category: string;
  name: string;
  description: string;
  price?: number;
  originalPrice?: number;
  discountedPrice?: number;
  uuid: string;
  id: string;
  pictures?: string[];
  url: string;
}

export interface IChatMetadata {
  type: ChatOutcomeType;
  actionId?: string;
  products?: {
    id: string;
    content: string;
    score: number;
    metadata: IProductMetadata;
  }[];
}

export enum ChatAttachmentType {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  File = 'file',
}

export interface IChatAttachmentRequest {
  type: ChatAttachmentType
  name?: string
  payload: IChatAttachmentRequestPayload
}

export interface IChatAttachmentRequestPayload {
  content: string
  contentType: string
}

export interface IChatMessage {
  id: string;
  body: string;
  createdAt: Date;
  senderId: string;
  contentType: string;
  metadata?: IChatMetadata;
  attachments: IChatAttachment[]; // used for response. This doesn't contain the actual content
  attachmentContents?: IChatAttachmentRequest[]; // used only for request
}

export enum CustomerStatus {
  // Active = "active",
  Handover = 'handover',
  Automated = 'automated',
  HumanSupport = 'human_support',
  NotAssigned = 'not_assigned',
  Tester = 'tester',
  // Closed = "closed",
}

export const CustomerStatusOptions = [
  { value: CustomerStatus.Handover, label: 'chat.handover' },
  { value: CustomerStatus.Automated, label: 'chat.automated' },
  { value: CustomerStatus.HumanSupport, label: 'chat.human-support' },
  { value: CustomerStatus.NotAssigned, label: 'chat.not-assigned' },
  { value: CustomerStatus.Tester, label: 'chat.tester' },
  // { value: CustomerStatus.Closed, label: "Closed" },
];

export enum ChatPlatform {
  Facebook = 'facebook',
  ChatWidget = 'widget',
  Playground = 'playground',
}

export type ICustomerItem = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  picture?: string;
  gender?: 'male' | 'female';
  address: string;
  status: CustomerStatus;
  isAutomated: boolean;
  isHumanSupport: boolean;
  isTester: boolean | null;
  lastMessageAt: Date;
  handOverAt: Date | null;
  handOver: Record<string, any> | null;
  platform: ChatPlatform;
  pageId: string;
  pageName: string;
};

export interface IChatConversation {
  id: string;
  platforms: {
    widget: {
      messages: IChatMessage[];
      customer?: ICustomerItem;
    }
  }
};

export type QuickAction = {
  label: string
  action?: string;
};

export type WidgetConfig = {
  quickActions: QuickAction[]
  themes: {
    headerBackgroundColor?: string
    backgroundColor?: string
    primaryColor?: string
    secondaryColor?: string
    logoUrl?: string
  }
  greetings: string[]
}