const USER_ID_KEY = '@concierge:user-id';

/**
 * Generates a new user ID.
 * @returns A new user ID.
 */
const generateUserId = (): string => {
  return Math.random().toString(36).substr(2, 32);
};

/**
 * Retrieves the user ID from local storage or generates a new one if it doesn't exist.
 * @returns The user ID.
 */
export const getUserId = (): string => {
  let userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    userId = generateUserId();
    localStorage.setItem(USER_ID_KEY, userId);
  }
  return userId;
};

/**
 * Clears the user ID from local storage.
 */
export const clearUserId = (): void => {
  localStorage.removeItem(USER_ID_KEY);
};
