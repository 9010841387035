import { ChatAttachmentType, ChatRole, CustomerStatus, IChatMessage, ICustomerItem } from "../interface"

export const tmpChatMessage = (text: string, { images }: { images?: string[] } = {}): IChatMessage => {
  return {
    id: `${Date.now()}`,
    body: text,
    contentType: 'text',
    createdAt: new Date(),
    senderId: 'user',
    attachments: [], // this is set by the backend
    attachmentContents: images ? images.map(image => ({
      type: ChatAttachmentType.Image,
      name: 'image.jpg',
      payload: {
        content: image,
        contentType: 'image/jpeg'
      }
    })) : [] // this is for the FE to render the temp image
  }
}

export const senderRole = (chat: IChatMessage) => {
  if (chat.senderId === ChatRole.Ai) return ChatRole.Ai
  if (chat.senderId === ChatRole.Staff) return ChatRole.Staff
  return ChatRole.User
}

export const chatStyle = (chat: IChatMessage): { color: string; textColor: string; } => {
  const role = senderRole(chat)
  if (role === ChatRole.Ai) return { color: '#eee', textColor: '#000' }
  if (role === ChatRole.Staff) return { color: 'navajowhite', textColor: '#000' }
  return { color: '#c8fad6', textColor: '#000' }
}

export const bannerColor = (customer: ICustomerItem) => {
  if (customer.status === CustomerStatus.Handover) return 'mistyrose'
  if (customer.status === CustomerStatus.HumanSupport) return 'navajowhite'
  return '#eee'
}