import { FC } from 'react';

import { useTranslation } from 'react-i18next';

const Divider: FC = () => {
  const { t } = useTranslation();
  return (
    <div style={{
      width: '100%',
      textAlign: 'center',
      borderBottom: '1px solid #ccc',
      lineHeight: '0.1em',
      margin: '20px 0',
      color: '#ccc'
    }}>
      <span style={{ background: '#fff', padding: '0 10px' }}>{t('chatbot.new-session')}</span>
    </div>
  );
};

export default Divider;
