import { FC, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import imageIconUrl from '../assets/image.svg';
import sendIconUrl from '../assets/send.svg';

type ChatInputProps = {
  input: string;
  setInput: (input: string) => void;
  sendMessage: () => void;
  answering: boolean;
  selectedImage: string | null;
  setSelectedImage: (image: string | null) => void;
  disabled: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
  background-color: white;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: left;
  margin-bottom: 10px;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;

  img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
    animation: scaleIn 0.3s ease-in-out;
  }

  button {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotateIn 0.3s ease-in-out;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  textarea {
    flex: 1;
    font-family: inherit;
    padding: 12px 16px;
    margin-right: 10px;
    border-radius: 24px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    outline: none;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #333;
    resize: none;
    max-height: calc(16px * 1.4 * 3 + 24px); /* Maximum height for 3 lines of text */

    &:focus {
      border-color: #00e68a;
    }

    &:blur {
      border-color: #e0e0e0;
    }
  }

  input[type='file'] {
    display: none;
  }

  label {
    cursor: pointer;
    margin-right: 10px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  button {
    margin-right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    img {
      width: 24px;
      height: 24px;
      fill: black;
    }
  }
`;

const ChatInput: FC<ChatInputProps> = ({ input, setInput, sendMessage, answering, selectedImage, setSelectedImage, disabled }) => {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target?.result as string;
        setSelectedImage(content);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = e.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const blob = items[i].getAsFile();
          if (blob) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const content = event.target?.result as string;
              setSelectedImage(content);
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !e.nativeEvent.isComposing) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current && !disabled) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight - 24, 3 * 16 * 1.4)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  return (
    <Container>
      {selectedImage && (
        <ImageContainer>
          <img src={selectedImage} alt="Selected" />
          <button onClick={removeSelectedImage}>&times;</button>
        </ImageContainer>
      )}
      <InputContainer>
        <textarea
          ref={textareaRef}
          rows={1}
          value={input}
          onChange={(e) => setInput(e.currentTarget.value)}
          placeholder={t('chatbot.placeholder')}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onInput={adjustTextareaHeight}
          style={{ height: 'auto' }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          id="image-upload"
          key={selectedImage ? 'selected' : 'not-selected'}
        />
        <label htmlFor="image-upload">
          <img src={imageIconUrl} alt="Camera" />
        </label>
        <button onClick={() => sendMessage()} disabled={answering || (!input.trim() && !selectedImage)}>
          <img src={sendIconUrl} alt={t('chatbot.send')} />
        </button>
      </InputContainer>
    </Container>
  );
};

export default ChatInput;
