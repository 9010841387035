import { useState, useEffect, useRef, useCallback } from 'react';
import { io, Socket } from 'socket.io-client';
import { API_URL } from '../utilities/api';
import { IChatMessage } from '../interface';
import { getUserId } from '../utilities/cache';

interface UseSocketOptions {
  apiKey: string;
  hostDomain: string;
}

const useSocket = ({ apiKey, hostDomain }: UseSocketOptions) => {
  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState<IChatMessage | null>(null);
  const socketRef = useRef<Socket | null>(null);
  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 5;
  const reconnectDelayRef = useRef(1000);

  const initializeSocket = useCallback(() => {
    const userId = getUserId();
    const socketUrl = `${API_URL}/v2/chat/ws`;
    
    if (socketRef.current) {
      // console.log('Socket already initialized, skipping...');
      return;
    }

    // console.log('Initializing socket connection...');

    socketRef.current = io(socketUrl, {
      transports: ['websocket'],
      query: { userId, platform: 'widget' },
      extraHeaders: {
        'X-API-Key': apiKey,
        'X-Widget-Referer': hostDomain
      },
      reconnection: false, // We'll handle reconnection manually
      timeout: 20000,
    });

    socketRef.current.on('connect', () => {
      // console.log('Socket.io connection established');
      setIsConnected(true);
      reconnectAttemptsRef.current = 0;
      reconnectDelayRef.current = 1000;
    });

    socketRef.current.on('message', (newMessage: IChatMessage) => {
      setLastMessage(newMessage);
      
      window.parent.postMessage({
        action: 'newMessage',
        message: newMessage
      }, '*');
    });

    socketRef.current.on('connect_error', (error) => {
      // console.error('Socket.io connection error:', error);
      handleDisconnect('connect_error');
    });

    socketRef.current.on('disconnect', (reason) => {
      // console.log('Socket.io disconnected:', reason);
      handleDisconnect(reason);
    });

    socketRef.current.io.on('error', (error) => {
      // console.error('Socket.io error:', error);
      handleDisconnect('error');
    });
  }, [apiKey, hostDomain]);

  const handleDisconnect = (reason: string) => {
    setIsConnected(false);
    
    if (reason === 'io server disconnect') {
      // The disconnection was initiated by the server, you need to reconnect manually
      reconnect();
    } else {
      // Else the socket will automatically try to reconnect
      reconnect();
    }
  };

  const reconnect = useCallback(() => {
    if (reconnectAttemptsRef.current >= maxReconnectAttempts) {
      // console.log('Max reconnection attempts reached');
      return;
    }

    reconnectAttemptsRef.current += 1;
    // console.log(`Attempting to reconnect... (Attempt ${reconnectAttemptsRef.current})`);

    setTimeout(() => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current.connect();
      } else {
        initializeSocket();
      }
    }, reconnectDelayRef.current);

    // Increase the delay for the next attempt (exponential backoff)
    reconnectDelayRef.current = Math.min(reconnectDelayRef.current * 2, 30000); // Max delay of 30 seconds
  }, [initializeSocket]);

  useEffect(() => {
    if (apiKey && hostDomain) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [apiKey, hostDomain, initializeSocket]);

  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (isConnected && socketRef.current) {
        socketRef.current.emit('ping');
      }
    }, 30000); // Send a ping every 30 seconds

    return () => clearInterval(pingInterval);
  }, [isConnected]);

  const sendMessage = useCallback((message: string) => {
    if (socketRef.current && isConnected) {
      socketRef.current.emit('message', message);
    } else {
      // console.warn('Cannot send message: Socket is not connected');
    }
  }, [isConnected]);

  return {
    isConnected,
    lastMessage,
    sendMessage
  };
};

export default useSocket;