import { FC } from 'react';
import styled from 'styled-components';
import { ICustomerItem, CustomerStatus } from '../interface';
import { bannerColor } from '../utilities/chat';
import { useTranslation } from 'react-i18next';
import humanSupportImage from '../assets/human-support.svg';
import handoverImage from '../assets/handover.svg';

type InfoBannerProps = {
  customer: ICustomerItem;
};

const Banner = styled.div<{ bgcolor: string, customer: ICustomerItem }>`
  padding: 10px;
  background-color: ${(props) => props.bgcolor};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  margin-right: 10px;
`;

const Text = styled.span`
  font-size: 12px; /* Adjust the font size as needed */
`;

const pickImage = (customer: ICustomerItem) => {
  if (customer.status === CustomerStatus.Handover) return handoverImage;
  if (customer.status === CustomerStatus.HumanSupport) return humanSupportImage;
  return '';
}

const InfoBanner: FC<InfoBannerProps> = ({ customer }) => {
  const { t } = useTranslation();
  const image = pickImage(customer);

  return (
    <>
      {(customer?.status === CustomerStatus.Handover || customer?.status === CustomerStatus.HumanSupport) && (
        <Banner bgcolor={bannerColor(customer)} customer={customer}>
          {image && <Image src={image} alt={customer.status} />}
          <Text>{t(`chatbot.${customer.status.toLowerCase()}-banner`)}</Text>
        </Banner>
      )}
    </>
  );
};

export default InfoBanner;
