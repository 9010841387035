import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IProductMetadata } from '../interface';
import { gdriveProxy } from '../utilities/api';

type ChatProductsProps = {
  products: IProductMetadata[];
};

const ChatProducts: React.FC<ChatProductsProps> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box>
        {products.length === 1 ? (
          <SingleProductBox>
            <ProductCard product={products[0]} />
          </SingleProductBox>
        ) : (
          <ScrollableContainer>
            {products.map((product) => (
              <ProductCardWrapper key={product.id}>
                <ProductCard product={product} />
              </ProductCardWrapper>
            ))}
          </ScrollableContainer>
        )}
      </Box>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  max-width: 100%;
  width: 400px;
  height: auto;
`;

const Box = styled.div`
  position: relative;
`;

const SingleProductBox = styled.div`
  padding: 1.5px;
  width: 100%;
`;

const ScrollableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 0.5rem;
`;

const ProductCardWrapper = styled.div`
  flex: 0 0 auto;
  width: 200px; /* Adjust as needed */
`;

type ProductCardProps = {
  product: IProductMetadata;
};

const getPictureUrl = (pictures: string[] | undefined): string => {
  if (pictures && pictures.length > 0) {
    if (pictures[0].startsWith('http')) {
      return pictures[0];
    }
    return gdriveProxy(pictures[0]);
  }
  return '';
};

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { id, name, url, pictures } = product;

  const pictureUrl = getPictureUrl(pictures);

  return (
    <ProductLink href={url} target="_blank" rel="noopener noreferrer">
      <ProductCardContainer key={id}>
        {pictures && pictures.length > 0 && (
          <ImageContainer>
            <ProductImage alt={name} src={pictureUrl} referrerPolicy="no-referrer" />
          </ImageContainer>
        )}
        <ProductName>{name}</ProductName>
      </ProductCardContainer>
    </ProductLink>
  );
};

const ProductLink = styled.a`
  text-decoration: none;
`;

const ProductCardContainer = styled.div`
  padding: 1.5px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  &:hover {
    background-color: #f0f0f0;
  }
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
`;

const ProductImage = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const ProductName = styled.p`
  margin-top: 1rem;
  color: #666;
  font-size: 0.875rem;
  text-align: left;
  padding: 0 0.75rem;
  display: block;
  white-space: normal;
  word-wrap: break-word;
`;

export default ChatProducts;
