import { useEffect, useState } from "react";
import { ChatOutcomeType, IChatMessage, IProductMetadata } from "../interface";

const normalizeText = (text: string): string =>
   text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, ' ')           // Replace multiple spaces with a single space
    .replace(/\s*([.,!?;:()])\s*/g, '$1') // Remove spaces around punctuation
;

export default function useProducts(message: IChatMessage) {
  const [products, setProducts] = useState<IProductMetadata[]>([]);
  const [productQuery, setProductQuery] = useState(false);

  useEffect(() => {
    if (!message.metadata || !message.metadata.products) return;

    if (message.metadata.type === ChatOutcomeType.QueryProducts) {
      setProductQuery(true)
    }

    const newProducts: IProductMetadata[] = [];
    const productIds = new Set(products.map(product => product.id));

    const messageContent = normalizeText(message.body);
    message.metadata.products.forEach((product) => {
      const productName = normalizeText(product.metadata.name);
      // Check if this product is mentioned in the message and hasn't been added already
      if ((messageContent.includes(productName) || messageContent.includes(product.metadata.url)) && !productIds.has(product.metadata.id)) {
        newProducts.push(product.metadata);
        productIds.add(product.metadata.id);
      }
    });

    if (newProducts.length > 0) {
      setProducts((prev) => {
        const updatedProducts = [...prev, ...newProducts];
        // Ensure uniqueness in the updated products list
        const uniqueProducts = Array.from(new Set(updatedProducts.map(p => p.id)))
          .map(id => updatedProducts.find(p => p.id === id) as IProductMetadata);
        return uniqueProducts;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.body, message.metadata]);

  return {
    products,
    hasProducts: productQuery,
  };
}
