import { FC, useEffect, useState } from 'react';
import { sendMessage as sendApiMessage, getConversation, deleteHistory, fetchConfig, setApiKeyAndReferrer } from './utilities/api';
import { CustomerStatus, IChatMessage, ICustomerItem, WidgetConfig } from './interface';
import { tmpChatMessage } from './utilities/chat';
import { getUserId } from './utilities/cache';
import ChatHeader from './components/ChatHeader';
import ChatMessages from './components/ChatMessages';
import ChatInput from './components/ChatInput';
import ChatSkeleton from './components/ChatSkeleton';
import QuickActions from './components/QuickActions';
import useSocket from './hooks/use-socket';
import InfoBanner from './components/InfoBanner';

const Concierge: FC = () => {
  const [customer, setCustomer] = useState<ICustomerItem | null>(null);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [input, setInput] = useState<string>('');
  const [initializing, setInitializing] = useState<boolean>(true);
  const [answering, setAnswering] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [config, setConfig] = useState<WidgetConfig | null>(null);
  const [apiKey, setApiKey] = useState<string>('');
  const [hostDomain, setHostDomain] = useState<string>('');

  const { isConnected, lastMessage, sendMessage: sendSocketMessage } = useSocket({ apiKey, hostDomain });

  useEffect(() => {
    if (lastMessage) {
      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(message => message.id === lastMessage.id);
        if (!isDuplicate) {
          return [...prevMessages, lastMessage];
        }
        return prevMessages;
      });
    }
    if (lastMessage?.senderId === 'staff' && customer?.status === CustomerStatus.Handover) {
      setCustomer({ ...customer, status: CustomerStatus.HumanSupport });
    }
    if (lastMessage?.senderId === 'ai' && customer?.status === CustomerStatus.HumanSupport) {
      setCustomer({ ...customer, status: CustomerStatus.Automated });
    }
  }, [lastMessage]);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      const { apiKey: receivedApiKey, hostDomain: receivedHostDomain } = event.data;
      if (receivedApiKey && receivedHostDomain) {
        if (process.env.NODE_ENV !== 'development' && event.origin !== receivedHostDomain) return;
        setApiKey(receivedApiKey);
        setHostDomain(receivedHostDomain);
        setApiKeyAndReferrer(receivedApiKey, receivedHostDomain);
        try {
          const configData = await fetchConfig();
          setConfig(configData);
          fetchConversation();
        } catch (error) {
          console.error('Failed to fetch configuration:', error);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const fetchConversation = async () => {
    const userId = getUserId();
    try {
      const conversation = await getConversation(userId);
      if (conversation && conversation.platforms?.widget?.messages) {
        setMessages(conversation.platforms.widget.messages);
      }
      if (conversation && conversation.platforms?.widget?.customer) {
        setCustomer(conversation.platforms.widget.customer);
      }
    } catch (error) {
      console.error('Failed to fetch chat conversation:', error);
    } finally {
      setInitializing(false);
    }
  };

  const sendMessage = async (messageText?: string) => {
    const textToSend = (messageText ?? input) || '';
    if (textToSend.trim() || selectedImage) {
      const userMessage = tmpChatMessage(textToSend.trim(), { images: selectedImage ? [selectedImage] : [] });
      setMessages([...messages, userMessage]);
      setInput('');
      setSelectedImage(null);
      setAnswering(true);

      try {
        const userId = getUserId();
        const newMessage = await sendApiMessage(userId, userMessage);
        if (newMessage) {
          setMessages([...messages, userMessage, newMessage]);
        } else {
          await fetchConversation();
        }
        // sendSocketMessage(textToSend);
      } catch (error) {
        console.error('Failed to send message:', error);
      } finally {
        setAnswering(false);
      }
    }
  };

  const refresh = async () => {
    try {
      await deleteHistory(getUserId());
      setMessages([]);
      setCustomer(null);
    } catch (error) {
      console.error('Failed to refresh chat conversation:', error);
    }
  };

  const onActionClick = async (label: string) => {
    sendMessage(label);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100%',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      overflow: 'hidden',
      backgroundColor: config?.themes?.backgroundColor || '#fff',
    }}>
      <ChatHeader refresh={refresh} close={() => window.parent.postMessage({ action: 'close' }, '*')} disabled={answering || messages.length === 0 || initializing} />
      {initializing ? <ChatSkeleton /> : (
        <>
          {customer && (
            <InfoBanner customer={customer} />
          )}
          <ChatMessages userId={getUserId()} messages={messages} answering={answering} />
          {/* {!isConnected && (
            <div style={{ padding: '10px', backgroundColor: '#ffcccc', textAlign: 'center' }}>
              Connection lost. Attempting to reconnect...
            </div>
          )} */}
          {messages.length === 0 && config?.quickActions && (
            <QuickActions actions={config.quickActions} onActionClick={onActionClick} />
          )}
          <ChatInput
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            answering={answering}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            disabled={!isConnected}
          />
        </>
      )}
    </div>
  );
};

export default Concierge;