import { FC } from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';

import useAttachments from '../hooks/use-attachments';
import useProducts from '../hooks/use-products';
import { ChatRole, IChatMessage } from '../interface';
import { chatStyle, senderRole } from '../utilities/chat';
import ChatProducts from './Products';

interface StyledMessageItemProps {
  bgcolor: string;
  textcolor: string;
  isuser: string;
}

const StyledMessageItem = styled.div<StyledMessageItemProps>`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.textcolor};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 88%;
  min-width: 120px;
  margin-left: ${(props) => (props.isuser === 'true' ? 'auto' : '0')};
  margin-right: ${(props) => (props.isuser === 'true' ? '0' : 'auto')};
  position: relative;
  animation: fadeInFromBottom 0.3s ease-out;

  &:hover .timestamp, &:hover .role {
    opacity: 1;
  }
`;

const RoleSpan = styled.span<{ isuser: string }>`
  position: absolute;
  top: -28px;
  right: ${(props) => (props.isuser === 'true' ? '-8px' : 'auto')};
  left: ${(props) => (props.isuser === 'true' ? 'auto' : '-8px')};
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const TimestampSpan = styled.span<{ isuser: string }>`
  position: absolute;
  bottom: -26px;
  right: ${(props) => (props.isuser === 'true' ? '-8px' : 'auto')};
  left: ${(props) => (props.isuser === 'true' ? 'auto' : '-8px')};
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const StyledMarkdown = styled(Markdown)`
  p {
    margin: 0;
    line-height: 1.4;
  }
  ol {
    padding-inline-start: 20px;
  }
  ul {
    padding-inline-start: 20px;
  }
  li {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const ProductsContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  ms-overflow-style: none;
  margin-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type ChatMessageProps = {
  userId: string;
  message: IChatMessage;
};

const ChatMessageItem: FC<ChatMessageProps> = ({ userId, message }) => {
  const { dataSrcs } = useAttachments(userId, message);
  const { products, hasProducts } = useProducts(message);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const href = e.currentTarget.href;
    if (window.parent) {
      console.log('openLink', href);
      window.parent.postMessage({ action: 'openLink', href }, '*');
    }
  };

  const renderMarkdown = (content: string) => (
    <StyledMarkdown
      components={{
        a: ({ node, ...props }) => (
          <a {...props} onClick={handleLinkClick} />
        ),
      }}
    >
      {content}
    </StyledMarkdown>
  );

  return (
    <div>
      <StyledMessageItem
        bgcolor={chatStyle(message).color}
        textcolor={chatStyle(message).textColor}
        isuser={senderRole(message) === ChatRole.User ? 'true' : 'false'}
        onMouseOver={(event) => {
          const timestamp = event.currentTarget.querySelector('.timestamp');
          const role = event.currentTarget.querySelector('.role');
          if (timestamp instanceof HTMLElement) {
            timestamp.style.opacity = '1';
          }
          if (role instanceof HTMLElement) {
            role.style.opacity = '1';
          }
        }}
        onMouseOut={(event) => {
          const timestamp = event.currentTarget.querySelector('.timestamp');
          const role = event.currentTarget.querySelector('.role');
          if (timestamp instanceof HTMLElement) {
            timestamp.style.opacity = '0';
          }
          if (role instanceof HTMLElement) {
            role.style.opacity = '0';
          }
        }}
      >
        <div id="message-body" style={{ position: 'relative' }}>
          <RoleSpan className='role' isuser={senderRole(message) === ChatRole.User ? 'true' : 'false'}>
            {senderRole(message) === ChatRole.User ? 'You' : senderRole(message) === ChatRole.Staff ? 'Staff' : 'Concierge'}
          </RoleSpan>
          {renderMarkdown(message.body)}
          {dataSrcs.map((src, i) => (
            <img
              key={i}
              src={src}
              alt="attachment"
              style={{
                maxWidth: '100%',
                marginTop: '10px',
                borderRadius: '8px',
              }}
            />
          ))}
          <TimestampSpan isuser={senderRole(message) === ChatRole.User ? 'true' : 'false'} className="timestamp" title={new Date(message.createdAt).toLocaleString()}>
            {new Date(message.createdAt).toLocaleString([], {
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </TimestampSpan>
        </div>
      </StyledMessageItem>
      {hasProducts && (
        <ProductsContainer>
          <ChatProducts products={products} />
        </ProductsContainer>
      )}
    </div>
  );
};

export default ChatMessageItem;
