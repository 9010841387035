import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
`;

const ChatSkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const ChatSkeletonHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
`;

const SkeletonAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 12px;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const SkeletonTitle = styled.div`
  width: 150px;
  height: 20px;
  background-color: #ccc;
  border-radius: 4px;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const ChatSkeletonMessages = styled.div`
  padding: 16px;
`;

const SkeletonMessage = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const SkeletonText = styled.div`
  flex: 1;
`;

const SkeletonLine = styled.div`
  height: 16px;
  background-color: #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: ${pulse} 1.5s ease-in-out infinite;

  &:last-child {
    width: 80%;
  }
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: 200px;
  background-color: #ccc;
  border-radius: 8px;
  margin-top: 8px;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const ChatSkeleton = () => {
  return (
    <ChatSkeletonContainer>
      <ChatSkeletonHeader>
        <SkeletonAvatar />
        <SkeletonTitle />
      </ChatSkeletonHeader>
      <ChatSkeletonMessages>
        {[...Array(2)].map((_, index) => (
          <SkeletonMessage key={index}>
            <SkeletonAvatar />
            <SkeletonText>
              <SkeletonLine />
              <SkeletonLine />
            </SkeletonText>
          </SkeletonMessage>
        ))}
        <SkeletonMessage>
          <SkeletonAvatar />
          <SkeletonText>
            <SkeletonLine />
            <SkeletonLine />
            <SkeletonImage />
          </SkeletonText>
        </SkeletonMessage>
      </ChatSkeletonMessages>
    </ChatSkeletonContainer>
  );
};

export default ChatSkeleton;
