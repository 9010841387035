import React, { FC } from 'react';
import styled from 'styled-components';
import { QuickAction } from '../interface';

type QuickActionsProps = {
  actions: QuickAction[];
  onActionClick: (message: string) => Promise<void>;
};

const QuickActions: FC<QuickActionsProps> = ({ actions, onActionClick }) => {
  return (
    <ActionsContainer>
      {actions.map((action, index) => (
        <ActionChip
          key={index}
          onClick={async () => {
            await onActionClick(action.label);
          }}
        >
          {action.label}
        </ActionChip>
      ))}
    </ActionsContainer>
  );
};

const ActionsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 8px;
  padding: 10px;
  background: transparent;
`;

const ActionChip = styled.div`
  display: inline-block;
  padding: 8px 16px;
  background-color: black;
  color: white;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  opacity: 1;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
`;

export default QuickActions;
