import { FC, useEffect, useRef } from 'react';

import { ChatRole, IChatMessage } from '../interface';
import TypingIndicator from './TypingIndicator';
import Divider from './Divider';
import ChatMessageItem from './ChatMessageItem';
import { senderRole } from '../utilities/chat';

type ChatMessagesProps = {
  userId: string;
  messages: IChatMessage[];
  answering: boolean;
};

const fadeInFromBottom = `
  @keyframes fadeInFromBottom {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ChatMessages: FC<ChatMessagesProps> = ({ userId, messages, answering }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div style={{
      flex: '1',
      padding: '1rem',
      background: '#fff',
      overflowY: 'auto',
    }}>
      <style>{fadeInFromBottom}</style>
      {messages.map((msg, index) =>
        msg.body === 'NEW_SESSION_START' && senderRole(msg) === ChatRole.Ai ? (
          <Divider key={index} />
        ) : (
          <ChatMessageItem key={index} message={msg} userId={userId} />
        )
      )}
      {answering && <TypingIndicator />}
      <div ref={messagesEndRef} style={{ height: '1px' }} />
    </div>
  );
};

export default ChatMessages;
