import { useEffect, useState } from "react";
import { ChatAttachmentType, IChatMessage } from "../interface";
import { loadAttachment } from "../utilities/api";

// NOTE: This is a placeholder for the thumbs up sticker. If the backend changes this, this should be updated.
const FB_STICKER_PLACEHOLDER = 'The user has sent the thumbs up sticker.';

export default function useAttachments(userId: string, message: IChatMessage) {
  const [hasImage, setHasImage] = useState<boolean>(false);
  const [hasAudio, setHasAudio] = useState<boolean>(false);
  const [extractedText, setExtractedText] = useState<string>('');
  const [dataSrcs, setDataSrcs] = useState<Array<string>>([]);

  useEffect(() => {
    if (message.body === FB_STICKER_PLACEHOLDER) {
      setHasImage(true);
      const thumbsUpUrl = 'https://assets.heyai.pro/concierge/common/facebook_thumbs_up.png';
      setDataSrcs([thumbsUpUrl])
      return () => {

      }
    }
    if (message.contentType === ChatAttachmentType.Image || (message.attachments.length > 0 && message.attachments[0].type === 'image')) {
      setHasImage(true);
    }
    if (message.contentType === ChatAttachmentType.Audio || (message.attachments.length > 0 && message.attachments[0].type === 'audio')) {
      setHasAudio(true);
    }
    if (message.attachments.length > 0) {
      setExtractedText(message.attachments[0].extracted || '')
    }
    const fetchData = async () => {
      const urls = await Promise.all(message.attachments.map(async attachment => {
        try {
          const data = await loadAttachment(userId, attachment.id);
          return data;
        } catch (error) {
          console.error("Error fetching attachment:", error);
          return null; // Return null or undefined so that filtering can remove it
        }
      }));
      setDataSrcs(urls.filter((url): url is string => !!url));
    }

    // Check for temporary images in attachmentContents first
    // This is to render images when the message is sent on the dashboard.
    if (message.attachmentContents && message.attachmentContents.length > 0) {
      const imageContents = message.attachmentContents
        .filter(content => content.type === ChatAttachmentType.Image)
        .map(content => content.payload.content.startsWith('data:') 
          ? content.payload.content 
          : `data:${content.payload.contentType};base64,${content.payload.content}`);

      if (imageContents.length > 0) {
        setHasImage(true);
        setDataSrcs(imageContents);
        return () => {
          // Cleanup logic if needed
        };
      }
    }

    if (message.attachments.length > 0) {
      try {
        fetchData();
      } catch (e) {
        console.error(e);
      }
    }

    return () => {
      dataSrcs.forEach((dataSrc) => {
        URL.revokeObjectURL(dataSrc);
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataSrcs,
    hasImage,
    hasAudio,
    extractedText,
  };
}
