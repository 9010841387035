import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';  // Use react-i18next instead of preact-i18next
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import jaTranslations from './locales/ja.json';
import viTranslations from './locales/vi.json';
import esTranslations from './locales/es.json';
import frTranslations from './locales/fr.json';
// Import other language files as needed

i18n
  .use(LanguageDetector)
  .use(initReactI18next)  // Use initReactI18next instead of initPreactI18next
  .init({
    resources: {
      en: { translation: enTranslations },
      ja: { translation: jaTranslations },
      vi: { translation: viTranslations },
      es: { translation: esTranslations },
      fr: { translation: frTranslations },
      // Add other languages here
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
